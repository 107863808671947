/* Titles
   @mixin ======================================================================== */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
    margin: 0 0 20px;
    line-height: 1.2;
    font-weight: 700;

    &:not(:first-child) {
        margin-top: 20px; } }


/**
 * Responsive titles
 */
h1, .h1 {
    /* 36px - 24px */
    @include responsive-tlt(24px, 36px); }

h2, .h2 {
    /* 30px - 22px */
    @include responsive-tlt(22px, 30px); }

h3, .h3 {
    /* 26px - 20px */
    @include responsive-tlt(20px, 26px); }

h4, .h4 {
    /* 24px - 18px */
    @include responsive-tlt(18px, 24px); }

h5, .h5 {
    /* 20px - 17px */
    @include responsive-tlt(17px, 20px); }

h6, .h6 {
    /* 18px - 16px */
    @include responsive-tlt(16px, 18px); }


/**
 * Lists
 */
ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0; }
