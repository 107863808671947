.logo {
    &__link {
        color: $dark;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;

        @include media-breakpoint-down(md) {
            img {
                max-width: 150px; } } }
    img {
        width: 100%; } }
