.form {
    margin-bottom: 120px;
    @include media-breakpoint-down(md) {
        margin-bottom: 50px; }
    h2 {
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 75px;
        @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 35px;
            margin-bottom: 20px; } }
    &__group {
        position: relative;
        margin-bottom: 16px;
        width: 100%;
        input[type='file'] {
            display: none; }
        textarea {
            height: 290px;
            width: 100%;
            padding: 16px;
            color: #828282;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            background: #FFFFFF;
            box-shadow: 5px 5px 39px rgba(48, 51, 54, 0.03); }
        input {
            width: 100%;
            padding: 16px;
            color: #828282;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            background: #FFFFFF;
            box-shadow: 5px 5px 39px rgba(48, 51, 54, 0.03); }
        button {
            background: rgba(178, 138, 42, 0.87);
            padding: 18px;
            width: 100%;
            font-weight: 600;
            font-size: 15px;
            line-height: 25px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-align: left;
            border: none; } }
    &__file-row {
        height: 48px;
        background: #F0F0F0;
        position: absolute;
        width: calc(100% - 2px);
        bottom: 7px;
        left: 1px;
        display: flex;
        justify-content: end;
        align-items: center; }
    &__file-button {
        cursor: pointer;
        background: #828282;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #F2F2F2;
        padding-left: 30px;
        padding-right: 30px;
        svg {
            font-size: 28px; } }
    &__politica {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            text-decoration: none; }
        @include media-breakpoint-down(md) {
            margin-bottom: 30px; } } }
