.counters {
    padding-bottom: 120px;
    padding-top: 50px;
    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 20px; }
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr; } }
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        position: relative; }
    &__bg2-wrap {
        background: url(/images/round_1.svg);
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        position: absolute; }
    &__item_1 {}
    &__item_2 {
        .counters__bg2-wrap {
            transform: rotate(30deg); } }
    &__item_3 {
        .counters__bg2-wrap {
            transform: rotate(90deg); } }
    &__item_4 {
        .counters__bg2-wrap {
            transform: rotate(290deg); } }
    &__bg-wrap {
        background: #FFFFFF;
        border: 9.19634px solid #E4E4E4;
        border-radius: 100%;
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        text-align: center; }
    &__title {
        font-weight: 700;
        font-size: 55px;
        line-height: 40px;
        text-align: right;
        letter-spacing: 0.05em;
        color: #B28A2A;
        margin-bottom: 22px; }
    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000; } }
