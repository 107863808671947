@use "sass:math";

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
}

.icon-arrow-up {
    width: (32 / 32) * 1em;
}
.icon-down_two {
    width: (450 / 238) * 1em;
}
.icon-file_icon {
    width: (23 / 24) * 1em;
}
.icon-gruz {
    width: (288 / 290) * 1em;
}
.icon-left {
    width: (377 / 419) * 1em;
}
.icon-logo_white {
    width: (158 / 46) * 1em;
}
.icon-main__bottom {
    width: (1925 / 130) * 1em;
}
.icon-main_left {
    width: (587 / 651) * 1em;
}
.icon-main_right {
    width: (587 / 651) * 1em;
}
.icon-pointer {
    width: (17 / 25) * 1em;
}
.icon-right {
    width: (377 / 419) * 1em;
}
.icon-round_1 {
    width: (288 / 288) * 1em;
}
.icon-triangle_down {
    width: (2000 / 135.09) * 1em;
}
.icon-up {
    width: (186 / 110) * 1em;
}
.icon-uslugi_1 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_2 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_3 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_4 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_5 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_6 {
    width: (192 / 192) * 1em;
}
.icon-uslugi_7 {
    width: (192 / 192) * 1em;
}
.icon-vk_icon {
    width: (31 / 30) * 1em;
}
.icon-why_us_bg {
    width: (1498.05 / 2000) * 1em;
}
.icon-yt_icon {
    width: (30 / 30) * 1em;
}
