.partners {
    background: url('/images/park_bg2.svg');
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
        background: none; }
    &__wrap {
        padding-left: 260px;
        padding-right: 260px;
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px; } }

    &__title {
        font-weight: 300;
        font-size: 64px;
        line-height: 75px;
        color: #000000;
        margin-bottom: 70px;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 35px;
            margin-bottom: 30px; } }
    &__text {
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 20px; } }
    &__logo-wrap {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%; }
        @include media-breakpoint-down(md) {
            margin-top: 0; } }
    &__bottom {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        img {
            width: 134px; }
        @include media-breakpoint-down(md) {
            margin-top: 15px;
            img {
                width: 50px; } } } }
