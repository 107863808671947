.about {
    padding-top: 150px;
    @include media-breakpoint-down(md) {
        padding-top: 70px; }
    &__wrap {
        padding-left: 256px;
        padding-right: 256px;
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px; } }
    h2 {
        padding-bottom: 78px;
        @include media-breakpoint-down(md) {
            padding-bottom: 0px; } }
    &__text {
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        padding-bottom: 85px;
        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 40px; } }
    &__img-wrap {
        width: 100%;
        img {
            width: 100%;
            max-width: 100%; } }
    &__track-wrap {
        min-height: 308px;
        background: url(/images/track_move.webp);
        background-size: auto 100%;
        background-repeat-y: no-repeat;
        background-position-y: center;
        position: relative;
        margin-top: -125px;
        @include media-breakpoint-down(md) {
            margin-top: 0; } }
    &__bottom {
        min-height: 210px;
        background: url(/images/triangle_down1.svg);
        background-size: contain;
        background-repeat-y: no-repeat;
        @include media-breakpoint-down(md) {
            height: 30px;
            min-height: 30px;
            margin-bottom: 30px; } } }
