.main {
    .wrap {
        position: relative;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1624) 2.28%, rgba(0, 0, 0, 0) 55.9%); }
    .main-bg-1 {
        background: url(/images/main_track.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: .5s;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        &.active {
            opacity: 1; }
        @include media-breakpoint-down(md) {
            background-size: contain;
            background-position: top;
            background-position: bottom; } }
    .main-bg-2 {
        background: url(/images/main_track_active.webp);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: .5s;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        &.active {
            opacity: 1; }
        @include media-breakpoint-down(md) {
            background-size: contain;
            background-position: top;
            background-position: bottom; } }
    .container-fluid {
        @include media-breakpoint-down(md) {
            padding-bottom: 180px; } }
    &__wrap {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        position: relative;
        justify-content: space-between;
        padding-top: 195px;
        padding-bottom: 300px;
        @include media-breakpoint-down(md) {
            padding-top: 0px;
            width: 100%;
            padding-bottom: 0; } }
    &__left {
        @include media-breakpoint-down(md) {
            display: none; } }
    &__middle {
        max-width: 100%;
        img {
            max-width: 100%; }
        @include media-breakpoint-down(md) {
            display: none; } }
    &__title {
        font-weight: 500;
        font-size: 38px;
        line-height: 50px;
        color: rgba(20, 20, 20, 0.5);
        span {
            color: #000000; }
        @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 38px; } }
    &__right {
        padding-right: 220px;
        @include media-breakpoint-down(md) {
            padding-right: 0;
            width: 100%; } }
    &__button-wrap {
        margin-top: 60px;
        @include media-breakpoint-down(md) {
            margin-top: 20px; } }
    &__button {
        background: rgba(178, 138, 42, 0.87);
        font-weight: 600;
        font-size: 23.2613px;
        line-height: 25px;
        text-transform: uppercase;
        color: #FFFFFF;
        border: none;
        padding: 22px 19px;
        transition: 0.2s;
        &:hover {
            transform: scale(1.1); }
        @include media-breakpoint-down(md) {
            font-size: 20px;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px; } }
    &__bottom-bg {
        background: url(/images/main__bottom.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 130px;
        @include media-breakpoint-down(md) {
            height: 40px; } } }
