.services {
    padding-top: 150px;
    padding-bottom: 170px;
    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 20px; }
    h2 {
        padding-bottom: 150px;
        @include media-breakpoint-down(md) {
            padding-bottom: 20px; } }
    &__item {}
    &__item-image {
        padding: 42px;
        background: #FFFFFF;
        border: 2px solid #E4E4E4;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
        img {
            width: 100%; } }
    &__item-text {
        margin-top: 35px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 66px;
        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin-top: 15px;
            margin-bottom: 20px; } } }
