.avtopark {
    margin-bottom: 250px;
    @include media-breakpoint-down(md) {
        margin-bottom: 30px; }
    h2 {
        text-transform: uppercase;
        margin-bottom: 100px;
        @include media-breakpoint-down(md) {
            margin-bottom: 40px; } }
    &__left {
        position: relative;
        width: 160px;
        @include media-breakpoint-down(md) {
            width: 100%; }
        &::after {
            content: '';
            display: block;
            position: absolute;
            background: url('/images/line__abtopark.svg');
            height: 274px;
            width: 112px;
            background-repeat: no-repeat;
            background-size: contain;
            top: 10px;
            right: -157px;
            @include media-breakpoint-down(md) {
                display: none; } } }
    &__list {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        li {
            cursor: pointer;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            color: #ABABAB;
            margin-bottom: 38px;
            &:hover {
                color: #000000; }
            &.active {
                color: #000000; }
            @include media-breakpoint-down(md) {
                font-size: 14px;
                margin-bottom: 5px;
                br {
                    display: none; } } } }
    &__wrap {
        display: flex;
        gap: 150px;
        @include media-breakpoint-down(md) {
            gap: 20px;
            flex-direction: column; } }
    &__image {
        display: flex;
        align-items: end;
        img {
            display: none;
            max-width: 100%;
            width: 100%; }
        img.active {
            display: block; } } }
