.header {
    @include media-breakpoint-down(md) {
        padding-top: 0!important; }
    &__wrap {
        background-color: #1E1E1E; }
    &__main-row {
        padding-top: 30px;
        padding-bottom: 20px; }
    .container {
        max-width: 1558px; }
    .logo {
        max-width: 400px; }
    &__phone {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        text-align: center;
        gap: 15px;
        max-width: 478px;
        @include media-breakpoint-down(md) {
            margin-top: 20px; } }
    &__phone-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between; }
    &__button-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between; }
    &__phone-left {
        a {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            text-decoration: none;
            @include media-breakpoint-down(md) {
                font-size: 18px; } } }
    &__phone-middle {
        span {
            width: 1px;
            height: 31px;
            background: #B28A2A;
            display: block; } }

    &__phone-right {
        a {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
            text-decoration: none;
            @include media-breakpoint-down(md) {
                font-size: 18px; } } }
    &__button-left {
        a {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #B28A2A;
            text-decoration: none;
            padding-left: 20px;
            @include media-breakpoint-down(md) {
                font-size: 14px; } } }
    &__button-right {
        a {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #B28A2A;
            text-decoration: none;
            padding-right: 20px;
            @include media-breakpoint-down(md) {
                font-size: 14px; } } }
    &__location {
        color: #FFFFFF;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        @include media-breakpoint-down(md) {
            display: none; } }
    &__location-icon {
        font-size: 25px;
        svg {
            fill: #fff; } }
    &__location-text {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px; }
    &__menu-row {
        background: #fff;
        padding-top: 24px;
        box-shadow: 0px 4px 20px rgba(53, 53, 53, 0.18);
        @include media-breakpoint-down(md) {
            padding-top: 0; } } }
