.why-us {
    margin-top: -40px;
    overflow-x: hidden;
    @include media-breakpoint-down(md) {
        margin-top: 0; }
    &__bg {
        background: url("/images/track_inner.png");
        width: 100%;
        height: 1000px;
        background-size: cover;
        background-position: top center;
        @include media-breakpoint-down(md) {
            height: 300px; } }
    &__title {
        text-align: right;
        position: relative;
        z-index: 2;
        span {
            font-weight: 700;
            font-size: 85px;
            line-height: 100px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.7);
            background: #fff;
            padding-left: 55px;
            padding-right: 55px;
            padding-bottom: 0px;
            display: inline-block;
            @include media-breakpoint-down(md) {
                font-size: 36px;
                line-height: 42px; } }
        &::after {
            content: '';
            width: 100%;
            height: 11.5625rem;
            display: block;
            position: absolute;
            background: #C4C4C4;
            top: 70%;
            z-index: -1;
            right: 24%;
            @include media-breakpoint-down(md) {
                height: 80px; } } }
    &__right {
        ul {
            position: relative;
            li {
                position: relative;
                font-weight: 500;
                font-size: 24px;
                line-height: 42px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #141414;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 20px;
                    padding-left: 15px; }
                &::after {
                    content: '';
                    width: 12px;
                    height: 23px;
                    display: block;
                    position: absolute;
                    background: url("/images/right__ic.svg");
                    left: -30px;
                    top: 10px;
                    @include media-breakpoint-down(md) {
                        left: -5px;
                        width: 7px;
                        top: 4px;
                        background-size: contain;
                        background-repeat: no-repeat; } } } } }
    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 200px;
        padding-bottom: 760px;
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            gap: 40px;
            padding-bottom: 20px;
            margin-top: -50px; } }
    &__left {
        position: relative;
        &::after {
            content: "";
            display: block;
            background: url(/images/gor_border.svg);
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: -82px;
            width: 69px;
            height: 1000px;
            top: 20px;
            z-index: 2;
            @include media-breakpoint-down(md) {
                display: none; } } }
    &__right {}
    &__wrap {
        background: url(/images/bg__whyus.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        padding-top: 311px;
        margin-top: -369px;
        @include media-breakpoint-down(md) {
            padding-top: 20px;
            margin-top: 0;
            background: none; } }

    &__bottom {
        background: url(/images/bg_usl.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        height: 67px; } }
