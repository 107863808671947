h2.title {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 64px;
    line-height: 46px;
    letter-spacing: 0.05em;
    color: #B28A2A;
    @include media-breakpoint-down(md) {
        font-size: 32px; } }
html {
    overflow-x: hidden; }
