.footer {
    color: #fff;
    padding-top: 90px;
    padding-bottom: 55px;
    background-image: url('/images/form-back.jpeg');
    @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px; }
    &__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 50px;
        align-items: center;
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            gap: 20px; } }
    &__logo {
        font-size: 45px; }
    &__contact {
        white-space: nowrap;
        a {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            text-decoration: none; }
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            color: #FFFFFF; } }
    &__link {
        text-align: right;
        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
            text-decoration: none; }
        @include media-breakpoint-down(md) {
            text-align: left; } }
    &__politic {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF; }
    &__bottom {
        margin-top: 40px;
        display: flex;
        gap: 20px;
        align-items: center;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: start; } }
    &__soc-wrap {
        display: flex;
        gap: 20px;
        font-size: 30px; }
    &__soc-item {
        svg {
            fill: red; } } }
